body {
    display: block;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

body {
    height: 100%;
    overflow-x: hidden;
  }

.home {
    width: 100%;
}

.header {
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header .name {
    text-transform: uppercase;
    background: linear-gradient(to right, red, orange);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10vw;
    font-family: 'Poppins', sans-serif;
    z-index: 10;
    margin-top: 0;
}

article {
    height: 100vh;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
}

article .before {
    background: linear-gradient(180deg, #ff8a00, red);
    padding: 3px;
    margin: 10px;
    width: 20vw;
    flex: 1 0 auto;
}

article .content {
    background: black;
    text-align: center;
    text-decoration: none;
    color: white;
    height: 100%;
    padding: 20px;
    font-width: 600 !important;
}

article .content a {
    text-decoration: none;
    color: red;
}

h1 {
    position: relative;
}

h1>a:before {
    content: "";
    background: linear-gradient(to right, red, orange);
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    transform: scaleX(0);
    animation: 1.4s forwards no-hover-v linear;
    animation-fill-mode: forwards;
    z-index: -1;
}

h1>a:focus:before {
    animation: .5s forwards hover-v linear;
    animation-fill-mode: forwards;
}

@keyframes hover-v {
    0% {
        transform: scaleX(0);
        height: 5px;
    }
    45% {
        transform: scaleX(1.05);
        height: 5px;
    }
    55% {
        height: 5px;
    }
    100% {
        transform: scaleX(1.05);
        height: 15vw;
    }
}

@keyframes no-hover-v {
    0% {
        transform: scaleX(1.05);
        height: 15vw;
    }
    45% {
        height: 5px;
    }
    55% {
        transform: scaleX(1.05);
        height: 5px;
        opacity: 1;
    }
    100% {
        transform: scaleX(0);
        height: 5px;
        opacity: .02;
    }
}

.stars, .twinkling, .clouds {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: block;
}

.stars {
    background: #000 url('stars.png') repeat top center;
    z-index: 0;
}

.twinkling {
    background: transparent url('twinkling.png') repeat top center;
    z-index: 1;
    -moz-animation: move-twink-back 200s linear infinite;
    -ms-animation: move-twink-back 200s linear infinite;
    -o-animation: move-twink-back 200s linear infinite;
    -webkit-animation: move-twink-back 200s linear infinite;
    animation: move-twink-back 200s linear infinite;
}

@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-webkit-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-moz-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-ms-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

.header .hey {
    color: white;
    font-size: 5vw;
    font-family: 'Poppins', sans-serif;
    z-index: 10;
    position: relative;
    margin-bottom: 0;
}

.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
    position: relative;
    z-index: 15;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.header .arrow {
    border: solid white;
    border-width: 0 0.7vw 0.7vw 0;
    display: inline-block;
    padding: 0.8vw;
    z-index: 15;
    position: relative;
}

.header .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.connect {
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.connect .title{
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    width: max-content;
}

.connect .title:before{
    background: #333;
    content: " ";
    position: absolute;
    top: 20px;
    height: 2px;
    width: 80px;
    margin: 0 5vw;
    display: inline-block;
    zoom: 1;
    right: 80px;
}

.connect .title:after{
    background: #333;
    content: " ";
    position: absolute;
    top: 20px;
    height: 2px;
    width: 80px;
    margin: 0 5vw;
    display: inline-block;
    zoom: 1;
    left: 80px;
}
.connect img{
    cursor: pointer;
}